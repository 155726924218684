<template>
  <div class="play-game position-relative">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="60"
      :width="60"
      color="#CC3636"
      loader="dots"
    />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'

export default {
  name: 'PlayGame',
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    gameId() {
      return this.$route.params.gameId
    }
  },
  methods: {
    createSession() {
      this.isLoading = true
      GameService.playGame(this.gameId)
      .then((response)=>{
        if(response.success) {
          window.location.href = response.data
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        }, ()=>{
          window.close()
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.createSession()
  }
}
</script>
<style lang="scss" scoped>
.play-game {
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: #F9BC02;
}
</style>
